<template>
  <div>
    <modal-confirm-generic
      ref="unSaveChangesModal"
      :description="$t('components/lims/modals/ModalFormCancel.text')"
      @onConfirm="onUnSaveChanges"
    />
    <component
      v-if="entityResource"
      v-bind:is="activeEntityType"
      :entityResource="entityResource"
      :formMode="formMode"
    ></component>
  </div>
</template>

<script>
import Clinic from './../Forms/Clinic/Clinic';
import Laboratory from './../Forms/Laboratory/Laboratory';
import ClinicLaboratory from './../Forms/ClinicLaboratory/ClinicLaboratory';
import { ENTITY_TYPES } from '@/core/constants';
import { EntityService } from '@/services';
import { UnSaveChangesMixins, ResetHeadingTitleMixins } from '@/core/mixins';

export default {
  mixins: [UnSaveChangesMixins, ResetHeadingTitleMixins],

  created() {
    this.entityResolver(this.entityId);
  },
  props: {
    entityId: {
      require: false,
      default: null,
    },
    formMode: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      entityResource: null,
    };
  },
  computed: {
    activeEntityType() {
      if (this.entityResource) {
        const entityTypeId = this.entityResource.information.entityType;
        if (entityTypeId === ENTITY_TYPES.Clinic) {
          return Clinic;
        }
        if (entityTypeId === ENTITY_TYPES.Laboratory) {
          return Laboratory;
        }
        if (entityTypeId === ENTITY_TYPES.ClinicLaboratory) {
          return ClinicLaboratory;
        }
        return false;
      }
      return false;
    },
  },

  methods: {
    async entityResolver(entityId) {
      if (entityId) {
        const { data } = await EntityService.findOne(entityId);
        this.entityResource = data;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
